import { config } from '../data/db-config';
import { AppUser } from '../data/app-user-model';
import { getFirestore } from './initFirebase';

export const addUser = (user: AppUser) => {
    const db = getFirestore();
    if (db) {
        const userCol = db.collection(config.user_endpoint);
        const userRef = userCol.doc(user.id);
        return userRef.set(user);
    }
}

export const getUsers = () => {
    const db = getFirestore();
    if (db) {
        const userCol = db.collection(config.user_endpoint);
        return userCol.orderBy('lastName').get();
    }
}

export const getUser = (userId: string) => {
    const db = getFirestore();
    if (db) {
        const userCol = db.collection(config.user_endpoint);
        const userRef = userCol.doc(userId);
        return userRef.get();
    }
}