export const toCamelCase = (entry: string) => {
    const entryArr = entry && entry.length ? entry.split(' ') : [];

    let newString = '';
    if (entryArr.length > 0) {
        entryArr.forEach((entry, index) => {
            const firstChar = entry.charAt(0).toUpperCase();
            const otherChars = entry.slice(1);
            const modifiedEntry = firstChar + otherChars;
            newString += `${modifiedEntry}${index !== entry.length ? ' ' : ''}`;
        });
    }

    return newString;
} 