import React, { ChangeEvent, useState } from 'react'
import Footer from '../components/footer'
import ResponsiveView from '../responsive/view'
import { Grid, Button, FormControlLabel, Checkbox, TextField, Container, Avatar, Typography, CssBaseline, IconButton, FormHelperText } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Logo from "../components/image-components/logo"
import { flexColCenter, flexColCenterAll } from '../common/format-styles'
import { signUp } from '../services/auth-service'
import { useForm } from 'react-hook-form'
import { toCamelCase } from '../helper/to-camel-case'
import { addUser } from '../services/user-service'
import { AppUser } from '../data/app-user-model'
import Notification from '../components/notification'
import AuthFooter from '../components/auth-footer'

interface Form {
    fname: string;
    lname: string;
    email: string;
    password: string;
}

const Signup = () => {
    const styles = css(`
        overflow-y: auto;    
        height: 100vh;

        .paper {
            margin-top: 5%;

            .avatar {
                width: 200px;
                height: 100px;
                margin: 2%;

                .logoCont {
                    width: 100%;
                }
            }

            .form {
                margin: 3%;

            }

            .submit {
                margin: 3%;
            }
        }
    `);

    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('');

    const handleSnackState = (open: boolean) => {
        setOpen(open);
    };
    
    const { register, handleSubmit, errors, triggerValidation, formState } = useForm<Form>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const onSubmit = (data: Form) => {
        const displayName = toCamelCase(`${ data.fname.charAt(0) } ${data.lname}`);
        console.log(displayName);
        const newUser: AppUser = {
            firstName: data.fname,
            lastName: data.lname,
            email: data.email,
            created_at: new Date(),
            role: 'public'
        }
        


        signUp(data.email, data.password).then(
            res => {
                res.user.updateProfile({displayName: displayName}).then(
                    () => {
                        newUser.id = res.user.uid;
                        addUser(newUser).then(
                            res => {
                                const href = newUser.role === 'public' ? '/' : '/app/admin_products';
                                navigate(href);
                            }, errors => {
                                console.log(errors);
                                
                            }
                        );
                    }
                );
            }, errors => {
                console.log(errors);
                
            }
        );
        
    }

    const handleChange = (event: any) => {
        const e = event as ChangeEvent<HTMLInputElement>;
        const target = e.target;
        const value = target.value;
        const name = target.name;        

       triggerValidation(name as any);        
        
    }

    return (
        <Container css={styles} component="main" maxWidth="xs">
            <CssBaseline />
            <div className="paper" css={flexColCenterAll}>
                <IconButton href="/" className="avatar">
                    <div className="logoCont"><Logo /></div>
                </IconButton>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="fname"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                onChange={handleChange}
                                onBlur={handleChange}
                                inputRef={register({required: 'Please enter your first name'})}
                                error={errors.fname && formState.touched.fname}
                                helperText={errors?.fname && errors.fname.message}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lname"
                                autoComplete="lname"
                                onChange={handleChange}
                                onBlur={handleChange}
                                inputRef={register({required: 'Please enter your last name'})}
                                error={errors.lname && formState.touched.lname}
                                helperText={errors?.lname && errors.lname.message}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"onChange={handleChange}
                                onBlur={handleChange}
                                inputRef={register({required: 'Please enter an email address', 
                                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})}
                                error={errors.email && formState.touched.email}
                            />
                            { errors.email?.type === "required" && <FormHelperText error>{errors.email.message}</FormHelperText>}
                            { errors.email?.type === "pattern" && 
                                <FormHelperText error>Please enter a valid email</FormHelperText>}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                onBlur={handleChange}
                                inputRef={register({required: 'Please enter password'})}
                                error={errors.password && formState.touched.password}
                                helperText={errors?.password && errors.password.message}
                            />
                        </Grid>

                        <Grid item xs={12}></Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="submit"
                    >
                        Sign Up
                    </Button>

                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/signin">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <AuthFooter />

            <Notification handleState={handleSnackState} msg={msg} open={open} />
        </Container>
    )
}

export default Signup
